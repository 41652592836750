import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-forward")])]},proxy:true}])}),_c(VDialog,{attrs:{"scrollable":"","max-width":"524px","max-higth":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Traceback")]),_c(VDivider),_c(VCardText,{staticStyle:{"height":"300px"}},[_c('pre',[_vm._v(_vm._s(_vm.traceback))])]),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('h4',{staticClass:"text-center py-4"},[_vm._v("Errors")]),_c(VBtn,{attrs:{"color":"cyan lighten-1 mr-2 ml-2","dark":""},on:{"click":function($event){return _vm.changeitemType('all')}}},[_vm._v("All")]),_c(VBtn,{attrs:{"color":"green lighten-1 mr-2 ","dark":""},on:{"click":function($event){return _vm.changeitemType('checked')}}},[_vm._v("checked")]),_c(VBtn,{attrs:{"color":"yellow darken-4 mr-2 ","dark":""},on:{"click":function($event){return _vm.changeitemType('not_checked')}}},[_vm._v("not checked ")]),_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"search":_vm.search,"footer-props":{
      'items-per-page-options': _vm.itemsPerPage
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.traceback",fn:function(ref){
    var item = ref.item;
return [[_c(VBtn,{attrs:{"color":"gray darken-1 ","dark":"","x-small":""},on:{"click":function($event){return _vm.openTraceback(item.traceback)}}},[_vm._v(" Open Traceback ")])]]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.checked)?_c(VIcon,_vm._g(_vm._b({staticClass:"m-2",attrs:{"color":"yellow darken-4"},on:{"click":function($event){return _vm.changeCheckedStatus(item, false)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-check ")]):_c(VIcon,_vm._g(_vm._b({staticClass:"mr-20",attrs:{"color":"green lighten-1"},on:{"click":function($event){return _vm.changeCheckedStatus(item, true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-bug-check ")])]}}],null,true)},[(item.checked)?_c('span',[_vm._v("uncheck error")]):_c('span',[_vm._v("check error")])]),_vm._v(" | "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"red darken-2"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("delete")])])]}},{key:"item.is_auth",fn:function(ref){
    var item = ref.item;
return [(item.is_auth)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green lighten-1","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("auth error")])]):_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"yellow darken-4","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("basic error")])])]}},{key:"item.checked",fn:function(ref){
    var item = ref.item;
return [(item.checked)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green lighten-1","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("checked error")])]):_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"yellow darken-4","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("not checked error")])])]}},{key:"item.sended",fn:function(ref){
    var item = ref.item;
return [(item.sended)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green lighten-1","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("sended error")])]):_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"yellow darken-4","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("not sended error")])])]}}])}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Are you sure you want to delete - "),_c('b',[_vm._v("'"+_vm._s(_vm.deletedItemName)+"'")]),_vm._v(" error?")]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"blue darken-1","dark":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"red lighten-1","dark":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }